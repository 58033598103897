var site = site || {};

(function ($) {
  Drupal.behaviors.videoFullWithV1 = {
    attach: function (context) {
      var $elements = $('.js-video-full-width-v1', context).once();

      if ($elements.length) {
        this.setup($elements, context);
      }
    },

    setup: function ($modules, context) {
      $modules.each(function () {
        init($(this));
      });

      function init($module) {
        var $moduleLink = $('.js-video-full-width__module-clickable', $module);
        var $play = $('.js-play', $module);
        var $reveal = $('.js-video-full-width__reveal', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isHTML5 = provider === 'html5';
        var isELCVideo = provider === 'elc-video';
        var isAutoPlay = $play.hasClass('js-play--autoplay');
        var clickToggleSound = $module.hasClass('js-click-toggle-sound');
        // @TODO: we may eventually add support for non-full-screen videos, as which point this
        // option should be toggled through the cms.
        var isFullScreen = false;

        $module.off('video.close').on('video.close', function () {
          // Pause youtube
          $('iframe', $module).remove();
          $module.removeClass('active');
        });

        $module.off('animation.triggered').on('animation.triggered', function (event, enter) {
          if (enter) {
            $(document).trigger('videos.pause');
            // @TODO -- Update to be according to inputs in INC file
            if ($module.find('video').length) {
              $module.find('video').attr('muted', true);
            }
          }
        });

        $play.off('click.play').on('click.play', function (event) {
          event.preventDefault();

          // Pass options with overlay / where to insert video content if not overlay
          var opts = {
            context: context,
            provider: provider,
            openOverlay: $play.hasClass('js-play--overlay'),
            $content: $reveal
          };

          $(document).trigger('videos.pause');

          // Triggered once video is loaded via JS and ready to show
          $reveal.off('video.loaded').on('video.loaded', function () {
            // When this video starts playing, let the content block handle the active state
            if ($(this).closest('.js-content-block').length) {
              $(this).closest('.js-content-block').trigger('video.playing');
            }
            $module.addClass('active');
          });

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
            if (isAutoPlay && clickToggleSound) {
              opts.click_toggles_mute = true;
            }
          } else if (!!isELCVideo) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          }

          if (isFullScreen) {
            opts.openOverlay = true;
            opts.width = '100%';
            opts.maxWidth = '100%';
            opts.height = '100%';
            opts.maxHeight = '100%';
          }

          if ($moduleLink.length) {
            opts.openLink = true;
          }
          $(document).trigger('video.open', [opts]);
          $reveal.find('.youtube-video').get(0).src += '&mute=1&playsinline=1';

          return false;
        });

        $('.js-video-full-width__close', $module).on('click', function (event) {
          event.preventDefault();
          $(document).trigger('videos.pause');
          $module.trigger('video.close');
        });

        function inViewport($elem) {
          var elementTop = $elem.offset().top;
          var elementBottom = elementTop + $elem.outerHeight(false);
          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();
          var fullyContained = elementTop > viewportTop && elementBottom < viewportBottom;
          var coversViewport = elementBottom > viewportBottom && elementTop < viewportTop;

          return fullyContained || coversViewport;
        }

        // handle showing the click to mute/unmute video message
        var _sound_message_timeout = null;

        $module.on('video.show-sound-message', function () {
          $module.addClass('enable-toggle-sound-message');
          if (_sound_message_timeout) {
            clearTimeout(_sound_message_timeout);
          }
          _sound_message_timeout = setTimeout(function () {
            $module.trigger('video.hide-sound-message');
          }, 1500);
        });

        $module.on('video.hide-sound-message', function () {
          $module.removeClass('enable-toggle-sound-message');
          _sound_message_timeout = null;
        });

        if (!!isHTML5) {
          if (isAutoPlay) {
            $module.on('video.loaded', function () {
              var video_element = $module.find('video').get(0);

              if (video_element) {
                $(video_element).on('ended', function () {
                  $(document).trigger('videos.pause');
                  $module.trigger('video.close');
                  if (site.client.isMobile) {
                    Drupal.behaviors.video.exitFullscreen(video_element);
                  }
                });
                $module.trigger('video.show-sound-message');
              }
            });
            // show the sound message based on mouse enter/exit
            if (isAutoPlay && clickToggleSound) {
              $module.on('mouseenter', function () {
                $module.trigger('video.show-sound-message');
              });
            }
          }
        }

        var process_autoplay = function () {
          if ($play.hasClass('autoplayed')) {
            return;
          }
          // only start after cover image is loaded.
          // the assumption is that the other assets above the video will have
          // loaded and taken vertical space. this requires that the autoplay
          // videos have a video image.
          if ($play.find('img.js-video-full-width__image.lazyloaded').length === 0) {
            return;
          }
          if (!inViewport($play)) {
            return;
          }
          $play.trigger('click.play');
          $play.addClass('autoplayed');
        };

        if (isAutoPlay) {
          $(window).on('scroll resize', _.debounce(process_autoplay, 300));
          // so check on load for the case where page only has video and so we
          // wont really be getting scroll/resize events
          $(window).on('load', function () {
            process_autoplay();
          });
          process_autoplay();
        }
      }
    }
  };
})(jQuery);
